import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cake-divider',
  templateUrl: './cake-divider.component.html',
  styleUrls: ['./cake-divider.component.scss']
})
export class CakeDividerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
