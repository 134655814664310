import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-venue',
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.scss']
})
export class VenueComponent implements OnInit {

  lat = 17.446107;
  lng = 78.384757;

  constructor() { }

  ngOnInit() {
  }

}
