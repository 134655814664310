import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twelve-month-pics',
  templateUrl: './twelve-month-pics.component.html',
  styleUrls: ['./twelve-month-pics.component.scss']
})
export class TwelveMonthPicsComponent implements OnInit {

  // tslint:disable: max-line-length
  imageArray = [
    {
      img: 'https://lh3.googleusercontent.com/PnNQy8w-yz_IS1QcCiOZgjU57d0TE68XqVir3qSdB1MogOUhus4MeeLcyu0q3WmtbUmHM7zqd0K5U8CW596rPmyvvKLAsbCZWAzz__gYobA-u8Vd55Sgx1QDYjJCCuoLNH2YLXOl9HkHF5fF2M9oXX7GCQbfSyA8drgjRgZxtxlqY0i_qYWSWeupCYZ1-5QgsxxKVPOyRAhFBsb1-HZBuqoR9TawWOcBhnBmwM-Pt1UOOAPg_BKimLLiF3wYISEcTh2GZd-7gt_IR5OvlpzeLeP8_qDYlUH8mavDkOCkjaHHbSNXUb5l6Qrb1-84e5eSnA4Mk0-cEIAvd_5s2jhRtaqh1scrTSOaoviOyJ5MEFi-X16AbN2YM2k_jmjCWjOLkJ39qgp5PkXWory4OIES7dOXPcWmzrxJ8k13E4f8yLtFvP5OS0w3lCgEwL6mEtERg9inSsl9iMmZNOB_bYxpI5vClqVfhrm1HiD0KT9oqLIu3LCyEYwmhG3hUHiyu5iNgOJOEjzEZVB7vGRptdKZdL2anU5Y05zT_xQEo7hfomuQX16jfxRPbC6l2nBmAQAEVW7j3FmS8KGas1b31dMeRzVa_4e89Z5RR_z3ljATsjGd_dExKbye3ocLvrryn2ZwNrUNt4qRqVaIjRetzCxXNx9_lN-ZcSE9r2WZvKjsGv3uzOdYSHFjvSfi3BP6VyWUmXeZnwFYpWXgc61tBueI50PkEw=w798-h789-no',
      description: 'Image 0'
    },
    {
      img: 'https://lh3.googleusercontent.com/ZGuzobodlWwhObdtEe89XRFnXUeV81dFRrCVojL5XRcMu6aruShfUK512dvUmER7aHgsIziuU01nMXiNm2Cyt3DHjlU-TUWzG2TX3igoxg2BVXc4_4tWg_lzz5WcO16N1ZD-sW4EkqLwmVWY8Y0WsKg3M-usPzVaT7yHowOksyvAoST5d8briqGbCAv38Mo6MZRzUFuWhVALQy4eKbkNeb_Of8QsAchn7SG69E43r99y-r2Q4UuaGZ-aD1XZI0_DwowosTmuSw8L0fHXdRK_FXuLtJYu-NgQcasu1Kcnp5TrLNxyH4WAUW0t-zLrHtqk7gjUfXsI2-IPrKVM1TyfWdgQ-1oE3BYxyFLT9Ie94XJWPjwX9epbEIYksxuSagFWnFxlM4iAOukmYeMYV5IpRpuVPQi8wt6dTczFDwBzF1KKVjRbRROonMD3b1HF9KjEvA3jfNkq1Zfl5rk3iRKGAJVZgBSeirUE5MNBh0np4QYTEU-zfMBGDRRdykHrAwQH-97AEqhxvuX5VgBkeMfKC7Rqf_LM3rXAJsyXbjrG93CgPqn-0lR77ffsBM9qsjtVjH36wyUNsxesICS3N98wvA3lv74h5ekaYXDC2LC2dZQmQO_bwAyXpMtAht2EONfxLLHzqpv7ZpDOQNQMVeF_a_4d3tKshQnf=w843-h789-no',
      description: 'Image 1'
    },
    {
      img: 'https://lh3.googleusercontent.com/DreVjRkvqDyVqerGqpoz0Ih1X9x_9JTuE_eBS2WCO2JfJr1LNpLtVe8qAfTdziFtpYMkgcgG6trsVlSvI69TUSCnLdDYJ88PYNpVE1uutZiVQ_GOQwcGIPwKlrxAlJWF4gvFXEQzPr1g3qfUhq7nR3vaEGIVFCK8K4HzxjAKT9T6_Mfzxr6ZQ5uewmAU3pG3Vtewtkkw1NGpVeV4Mr5QbxP9K5OcHp2VpcyOVIYPzT8aB5QqF8MTl9ptxY48pWeOJTyu3lJ7_5bs_jLv1ROh9J9OMr_-WGSEV0gumnqS_vQisx-0hBActo1881ALOkEVd_GZ4lUWR6OZx_BE6h6DxtRYXZkufpyZtKQM0f4kNJuVLvpx6L8ZX7JOiUc5x7gilf7ekvawk3mkOvI242kw4RQAOiM-eTjRq7CHzeQT0vJcUOa5uizxUXNIimRgvVOAxC7-Qwmak-71OWd5-nyhx7wnCHPHZHq4SUTBLmTpWysGthtYkwrq4jTz5QNtLJKEWMdib0zoZHBxqgD3ke7b6XVvCgSV1Ju8BqQGX5WU7ceFxW_HqA-3XW07HRVsIeZRFfVBTmltSzEjYApdHaAf-iprUoJGva5vJ6QQ_KvOp7MtmB8uCSsP0MGTTh_FgxLjtIYaI_a19AhgcAxHfUwsabT7lb7DDV5d=w853-h789-no',
      description: 'Image 2'
    },
    {
      img: 'https://lh3.googleusercontent.com/zGjlfvxWI0kAz-O4lldJoViCUwokxqAdpB5ShSewe72K8qJKZ_REaNukv32Z8S4QIFdbqZudqz94jFzUYKceaLZMCREJwo5_lh7k7x3aKHUicGhsL9OP18juKQ8vR4f6xfhMxMYhFLJSIU74EEuh4M8XrVCPYs2u3G_P3xIB4nacBuUuDdBPyyJkPdJX9ZOXn5pvpxXR7-K970SIO_fIfWRlOA05HTaUNYA9UGatKwHDNRXwtZU5NLH4weSF6vmIq0xjCImUHyRip6Lt7pGiqEKJ4Ia0k2m9M5684XPcxSMHn02efjaKoBqkp_LP91bQcgLvRvSAYQJsOnmqy4rLsnOs_wq2HtxinU-YalJ1bAfdSIBMC1jGypa-Ucbbvw4MpZtQ0IVsPhln7ffphBi28dCgMN6w5wdCZ6WLaNC2DcuGAqBHXMFA_BDB30bslMp05rQm6QwHpYfqRuyi7A2wtDiPlGTvHu8YZZF_OeB8T6eqTqTbZsbJjupKjwds7RnEL_vd924BdtTpGj9mXZ00O26EOMPVAlkAaIjf_kyXelNKKqVbGQC9m_SdUZgJM32LeTfEh9TU_0UuY3FnL0hSFLi4P-uXayHM1_yyN0kDL2S74Q_Xqn75TNhzSE2BxP3N2kSpAly0mW2GmCnr8JKgJdHVb12qZYkV=w850-h789-no',
      description: 'Image 3'
    },
    {
      img: 'https://lh3.googleusercontent.com/J5e3ucb5-_9aQt-zH52k-ahabW_4843wpGIL0J77mWO7anQN0Abn1AaTkvgZFEfa-aoPb3jYHmjpwi5igPGMi9zWUzpC7mMZpnOe8PtQVXaCuLNICyPjB5lxSvYZ5nSg6Wn3LCtXfwea8FfByr0jeEpYHsKIZKJt622IT7AFhoRxhASclVrcnCmGmJ8n1CuwLeR_6ExzQ55O-2_X0ZT5FgNq2I2oIzb3NbJVyT2VB4Fy5tm-pa8InWVcA8yWM9Y38e2x5XJHAk3fhU2IjUoo7vCMQhdmD_IYxMKZDESnKLvePMtAdlugy592-uBWo6XlVqtuuR5ZjXUuyH4bmujx242qdM--Q-xNiYO8CQqomPERUVHCZcuQpHRZGyAEHDq-DWR8VmNqma3ku1psF0l2dY0mB5qqSKswOrc4q9lVaE5oqFsAhddjmma2lpmgjRfl4vVJT0U3jOdkQhcX-7dkhyK7iJAV2Ad-qwVX3oekANLk-wIX4PCMTiNunsh7YgK9GqaEydoq6eqSjOBPPGdlXOXNXQwpaUx7KMoukZj5oZFuLY_O3UpUCE9bi6al-2mMor1cD12pwh6qN8_U02oBWOSXv2UoAijQbLZG7n64ypnWGxZlpy8I3hWG8def5fWqBI_Gw_2ov14MTxSLWaNaWmpB4rKvPKCQ=w880-h789-no',
      description: 'Image 4'
    },
    {
      img: 'https://lh3.googleusercontent.com/bX0fY9zP_f0LI5JANVBWhmrfx470OGp6jc4baZ34zgu1ziLPsxFshpaR82DPVy9HdYZMJUgV0LH7BBb8pjkrBa11Gh1CPLUJwMxhzPX5fM2nu9BDrj83pyQS86V3m2_DBuMLSkirwsZlJD4CIcCVe9H7OoKubs39M4YN1rf9-VG3rieV_TO7t4I_k_P7-vySeraCVcOcwBO6U_GX6baO0MVne5QH1gsfBLSmZ1t-5klKJANYSluf30pnP3BdHcJJMxva9FHGMa3x5ujDx6zsghDBiZ9IE_r_GgRtPGhFYzow397m3c8tu784B0adoIdi3_ozQt-Zw0FPOxs8iPyqRwFIc1Ye1Ujc0Rw8nghU3rX_KBve-fh4SiwStiebqv02SRfFIbixProggIco1164o8f2h7QBZ-9ecJeS-C_krk7rzNDY9APkpGRjbVgsXvUj_Qfxk---NsFfCXBx_EjxVLX46UqwKvwPDoPP0A1n3aGiH085KECVvIV3FxS7HE5oSDRlODxn4lg9wvtO4n9p8FqrRmH98zxls_f74J4rXkOdqJTmse7HPNgVIw5P9gzm9hp5dONJV127oIDGXA0wUtm-aWs7BQEwoXcuB6mUGtO_fDt9lHO1IngLAzd7gYgeXJJUwOKSV_wgga_M8FBmRpvwHFzNxije=w870-h789-no',
      description: 'Image 5'
    },
    {
      img: 'https://lh3.googleusercontent.com/W-ziewznIUofaIxvU5Ww-Pe_iB9ZZIsenveIbzxLIYLbU2vY5C4OgyzO7FEAiBoRln28LFVvAo7q_SpVgyOqPTyfC2rihKxHf4RYv3FSxP2kKHhZ2XZjWWvS-TXdFzrPIuhsrAOp0Fi4RGAWunN9D9UVK9qBQTOJCrBG3Adnqi2eOmEPNW_6kZ1IXjjhMLxs5Ax2tIaucIpKg9UpHLQhCBpsWKVmJx_l3CW1jAU9oFhc33NxVDoT_fx2KiojlOwC6sdISkVlvxlX2s4pMZDzfkqBRjlHKvvOPxsE0UUdlaI-A9_HIhfo09omGxHwLydAaO0QncCZnbNx4o9EQS2YFDGYNGY_C3Oxk9itx47AvgmMf4fvts8ApwmsItl1DRxO2-5wFQ7BUBJk63alNKSNDar54fHgoD7XQDEzbAj77dl2g-szPjT5IDBm5EyDkx97PLUzwYCHNkgYNVyZs-pn8I95EsD-rU7sATBU4uCQZWMZixlbLUyXLX4gDfAoLI6-uogMXXQZi5DMptfAi33Yt7e7YDpyslQp76Zzcsu8aTCbq9mzWBGV_HU_4-7KnTbAOdCONdQzho0m3qctQC4yKV-GeI3NfLBK0GEVJRpdcyG5k9Lm8ynaPLoVVm5dq969QhN1pYhkvx7_0bf_ppX26n1z_rEUqwZW=w877-h789-no',
      description: 'Image 6'
    },
    {
      img: 'https://lh3.googleusercontent.com/pRaQnC5SOxemm2OxpINP51h2muE0fgISNQwQeX3ZDrwFimyp-57dQuD8yRkX1GHFT8Na6nbiu7R6B1KmjNF5IrZP-sJlCVuzJ7QoAy057Tjjym_ctWhvVs2wq1lXW0upijbpy17_XA3tK_x0mUi_0bLJ2r_W_vYzPdfdR-lMPPjEP1FNXgNmc1G6RE2_viy5fDcSZleIvharW7IoOu9Zs9ozAY3RjBg8Qd60axtWPggR9pZmBRlk5dVupPHGSmkwhnsXKiEp20IYTpkB2bEtL7pAfvYCAy9DkLMeVqTOW-ERjx-oFAGehbSy8LbYJW4GHbAJCu3ACiRjxUM4Z109BMLRa-wAD1JO1W0_ISqxJe27ySPxy_80p7jz6pSphBQYTya4hhwz6Hs1mGEa_37ecAXdqsB7aG8_0Skk1eOEyebzoqRi1Zm1AIdZs9DByQiju8HOnp8sCkD-3fQUCX27GtcMfDMrFY3FTMo04XGNoeKd5wRhMvtu9-HHn-tfueSAC1_mH6_ETRt2z5eb5AIcV0BPLDRFPevPMuGDghXMPaPjiH7CKEv7BGRi4CKIpihmjAtvFLlXdwKLEhN6p04X69XT5m0aClCF1JAOtVucSUgl3Gv6eWcmiInpmK7-k3UK9wTYsZHmKqTbf-OYo6gdjNNKXfOnZx71=w829-h789-no',
      description: 'Image 7'
    },
    {
      img: 'https://lh3.googleusercontent.com/w8xaK4t7NgtjbeTP9RJ6CrA1D8SjWR4GKd98n55J4CuMdsvH4_wB9857I3TOqa5ON7L-HAZXUJsPwRlVFGnNs_5AoL5Oo6iyErxXSCiBmb5W0p_WoECLcrFcbvAPKULrNCDdlrmR2Em_N8jLLKXeuYGpOfUZb42XD3EYiLQPMMq66GVNUO3LSOeArZ_iAdN2FB6svWR_bb-vHv7SYPMH_sJ52Ge6eWGXvT6pHGn3TlCRsjd6l1_TVsbclF2vxNNzeW5pmEBHPbBDEZm4NLYZ-c3y-IlRdiEuIEkZvo61c-0J9Mdk1LFF1lt9cz2mG2WATt1qNTGwlTXkntR5C0rwtlB0Op36lIbDN6d7RisxUFPPsujgwA5BW-9A0Dhx93U-WHLFBUkXlZHVLaCTbBsUINAHFd1ENijhJ0ZLqMwqka_zcj-2TviXsQZd4RwQocyFW7pNuYvg-ztkt0wifHZWYZzVp3vyQS_f2KISenm00xGHVHVEyvWH6JgduA8m2geEwti7kxxAM19yJ-zqplT8bpojWFizlKdKq0dYHgsdW6cNwiJab104Z_V21aZ6ERTvSF8KPoDyL9A7ZUWZFP2qh43bwN6302o93RXuxZWx8vqENABwSzpdol0_Gv5fDw1zdeVfUQE0fGuK9-2Rou79aGfIaRJGXMmZ=w903-h789-no',
      description: 'Image 8'
    },
    {
      img: 'https://lh3.googleusercontent.com/Z79rQSnlzCjvLGmL6bnJjh3vfZU6tyU0RGgIJ7BknsZYrq-v0uvg4W1LS2Yp8QPcWINSRE4PZSqpc-i3l4o_iYtkZad3SDTajfjJSkCBVlXIHdb6vb007yBAinJs3BeGxV9XTqofUaNax_g-DJEHjjdFLwab8OXlj9SyTcZGTSZ-JfRe4QfZBn_udAQc7JgDQw9-LMEuehrUVPjIZOwlMCdoTxRxWRH2uQKhfMqjgD-078eAlkQBpC3rNulJjf8hq7APqupAIX1JhbzwVRLydNUVbRRs3pzn5cvC8FdmNG3PYa_E7Nf93Ejpn-rs1d3JpeJKyyc0GulYIF-hHnaLoByWiEbaVUdAPkGqquQk_FP5EG35n4_ef7fuR7375rpeYwZKnTBOUaXc8uIZb6d6B8EHyE4kIExwCon4ZSmp3vJ03E3DADeyVS5q6oiEU-I-kge7swJxjY8-dxTKcb0D4lM3ek9vCStSrWZUTR2kQzNEM699VknkqVHJ1S5EwnYxdBufx9aL6izsewHdnblojIltgPQz7GPae88eygd3Fnm7N8qtt63dEblQjor-_F43RF28AjtPSKJoqo21OKf8342_U-kGVP6YX4z6463bWwHbfPunr1m_pioPZwzd9E65O6H5jTHMeN7t3YHi_kuiBf9V7y-CyNZD=w854-h789-no',
      description: 'Image 9'
    },
    {
      img: 'https://lh3.googleusercontent.com/3-KGH3HscrY7dufp5I26A43fZtsc4N2e1R5A-SFI1JRW6fZy8xx1i16zyZrMZnryIlgY-Jk0UW9TSjVMbrFCu77mBdHlq7ddVH0tEGrZj-hKZtM2ciAFAnW1g5zWJEHHEGVqCcTba4P1OIhC6dgXIfmnVBJ0DEftz0jefwqI0z2A3pVnCbywadzhg9bbcN27I-UxwceIehGQwHaCpIAmi8Vj3BavybIQtRyZteokqRpBURBKP5fBn-QB0FllYN7lYjNG03Vt7kCWgBU4VPNJ3IXP5-YDTuL69V93_sXxYg38iSm8-mpretWHq895gMCySrkbt3kdKv6-ekT2letR6emIwZoMWeTCCdOOI-xQMv_Eq28pRKQqLfh-yEVukQV36Gll52wiVrp4d3i2d7xCjc6Q1pUB_Jc7brToiuRf1nj3RstOLWF9QFWJfHfpqp6Dk5C9gK4I8dyLQXo-cfpiSBA2Unx3gKkDcmxF7WZniar3-aRMHsgG8dgfXsnvthO91eaXXxgEXjEtYJttH-MuWwPt6mGIU87_5kSLO_Pxz2rMVBWgygNPUr6d2TXd0Z5TE3o1BCXL8AcBo1PQoRd7yS9kogCvUwyx5H3vu62e8cdBPgCyj-TFJHEKc51duJ1zikZ83zCUa1Z2acIC7orxrjqHfT-0rAgV=w897-h789-no',
      description: 'Image 10'
    }
  ];

  constructor() { }

  ngOnInit() {
  }
}
